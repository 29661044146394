* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Courier', sans-serif;
}

.App {
	background: #000000;
	position: relative;

	.background_img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
